.bg-img 
{
	overflow: hidden;
	width: calc(100% - 185px);
	right: 0;
	background-color: #4dabf2;
}

.text-welcome {
    line-height: 75px;
    font-size: 40px;
    margin-top: 9rem;
    font-weight: bold;
    margin-left: 4rem;
	position: relative;	
	z-index: 1;
}
.text-fair {
    -webkit-text-stroke: 4px #fff;
    text-stroke: 2px #000;
    color: transparent;
    font-size: 85px;
    font-weight: bolder;
    margin: 0;
}

.text-first 
{
	color: #000;
	font-size: 70px;
}

.admin-pn {
    background: #233986;
    color: #fff;
    width: 300px;
    border-radius: 17px;
    font-size: 27px;
    margin-top: 15px !important;
    display: block;
}


.Hero_Image
{
	max-height: 100%;
}

@media (max-width: 1200px) 
{
	.Hero_Image
	{
		display: none;
	}
}